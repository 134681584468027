.home-box{    
     color: rgb(94, 65, 65);
}
.home-box .navbar{
    padding: 0 20px;
    color: #fff;
}
.home-box a{    
    color: #fff;
}
.home-box a:hover,
.home-box a:focus
{    
    color: rgb(206, 40, 40);
}
.home-header{
    background-color: #f8f9fa;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    color: rgb(94, 65, 65);
    z-index: 0;
    /* background-size: 400% 400%;
    animation: gradient 20s ease infinite;
    clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%); */
}

.home-header::before{
   content: "";
    background-image: linear-gradient(-45deg, #34e0b886, #fbb016, #fbb016 , #23a6d5, #34e0b886,#34e0b886);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* clip-path: polygon(0 0, 100% 0, 100% 35vh, 0vh 100%); */
}

.home-header .container{
    width: 80%;
}
.home-header .container .row{
     /* margin: 40px 0; */
}

.home-header .col-sm-5{
    text-align: left;
    justify-content: center;
}

.home-header .ui.image{
    width: 100%;
    height: auto;
    /* padding: 0; */
    /* border-right: solid 2px rgb(94, 65, 65);
    border-bottom: solid 2px #fbb016; */
}
.ui.items{
    margin: 1.5em;   
}

.ui.items>.item>.image>img{
    border-radius: 10px;
    padding:0;
}

.main-box{
    /* margin-top: 40px; */
    margin-bottom: 40px;
}
/* .main-box:nth-child(odd){

}
.main-box:nth-child(even){
} */
.text-box{
    background-color: rgb(94, 65, 65, 0.8);   
    color: #fff; 
     padding: 50px 0;
}
.text-box .row{
   width: 80%;
   margin: 0 auto;
}
.text-box .row:nth-child(1){
    justify-content: center;
    margin-bottom: 20px;
}
.container-one{    
    position: relative;
    z-index: 0;
    font-size: large;
}

.container-one::before{    
    content: "";   
    /* background-color: #fbb016; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-size: 200% 200%;
    /* animation: gradient 20s ease infinite; */
    /* clip-path: polygon( 60vw 100% , 0vw 20%, 100% 0vh, 100% 0vh); */
    /* 30% 20vh, 100% 10vh, 90% 35vh, 10vh 100% */
}

.container-one .row{
width: 80%;
 margin: 20px auto;
}
.container-one .row:nth-child(odd){
    /* background-color: indigo; */
    

}
.container-one .row:nth-child(even){
    /* background-color: hotpink; */
    text-align: right;
} 
.container-one .col-5{
    /* background-color: aqua; */
}

.container-one .col{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
.container-one .ui.image{
    width: 100%;
    height: auto;
}

.container-two{
    background-color: #fbb016;
    margin-top: 40px;
}
.container-two .row{
    padding: 40px 0;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.radded-edge{
    background-color:rgba(0, 203, 169, 0.2);
    margin-top: 40px;
    padding: 0;
    text-align: center;
    margin-bottom: 0;
}
.radded-edge h1{
    font-size: 30px;
    padding: 20px 0;
}
.radded-edge p{
    padding: 20px 0;
}

.radded-edge svg{
    display: block;
    width: 100vw;
    margin: 0;
}
            
            



/* Responsive */
@media (max-width: 575.98px) {
.container {
    max-width: 100%;
}  
.container-one .row{
    flex-direction: column;
}
.container-one .col-5{
   flex: 0 0 80%;
   max-width: 80%;
   margin: 0 auto;
}
.container-one .col{
    width: 80%;
    margin: 0 auto;
}
.container-one .ui.image{
    width: 100%;
}

.container-one .row:nth-child(even){
    /* background-color: hotpink; */
    text-align: left;
    flex-direction: column-reverse;
} 
/* 
.container-four .row,
.text-box .row{
    flex-direction: column;
}
.container-four .col-5,
.text-box .col-5{
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 auto;
}


.bonus-box .col{
   flex: 0 0 50%; 
   margin-bottom: 20px;
} */

}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .container {
    max-width: 100%;
}

.container-two .row{
    flex-direction: column;
}

.container-two .col{
    width: 80%;
    margin: 20px auto 30px auto;
}

.container-two .ui.image{
    width: 100%;   
}

}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
.container {
    max-width: 100%;
}

}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
.container {
    max-width: 100%;
}
 }

 @media (min-width: 1199.98px) { 
.container {
    max-width: 100%;
}
 }


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}