.cart-container{
}

.cart-container .row{
    /* background-color: blue; */
}

.cart-container .row .col:nth-of-type(1){
    /* background-color: blue; */
}

{
    /* background-colorgb(78, 50, 104)let; */
}
.cart-container .note-header,
.cart-container .note-box{
    /* background-color: red; */
    width:100%;
    margin: 0 auto;
    padding: 2px 2px 2px 32px;
    /* margin: 0 50px; */
}