.whyUs-container{
    background-color: rgb(243, 200, 83);
    display: flex;
    flex-direction: column;    
    justify-content: center;
    text-align: center;
    padding: 20px;
    font-size: 16px;
}

.whyUs-container h2{
    font-weight: normal;
}
.whyUs-container .list-container{
    display: grid;
    grid-template-columns: repeat(20, 1fr);
}

.whyUs-container .list-box{
    grid-column: 2 / span 18;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
}

.whyUs-container .item-list {
    grid-column-end: span 4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* BUTTON STYLE */
.btn-container {
    margin-top: 10px;
}
.whyUs-container .btn-outline-primary {
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .whyUs-container .list-box{
        grid-column: 4 / span 14;
        grid-template-columns: repeat(8, 1fr);
    }
    
    .whyUs-container .item-list {
        grid-column-end: span 8;
    }
    
}


/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .whyUs-container .list-container{
        grid-template-columns: repeat(4, 1fr);
    }
    
    .whyUs-container .list-box{
        grid-column: span 4;
    }
}


@media (max-width: 575.98px) {
}


