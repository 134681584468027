.business-box{    
    color: rgb(94, 65, 65);     
}

.navbar{
   padding: 0;
}
/* GENERAL STYLE */
.body-container{
   /* background-color: coral; */
   min-height: 100vh;
}
.business-box .top-header a{    
   color:rgb(94, 65, 65) ;
}
.business-box .top-header h2::first-letter{
   font-size: 2.5rem;
   font-weight: bold;
}
img{
   width: 100%;
   height: auto;
   padding: 10px;
}

/* HEADER styling */
.header{    
   border-bottom: 2px solid rgba(34, 34, 34, 0.08);  
}

.header .form-inline{
   flex-flow: row nowrap;
}

.business-box .top-header,
.business-box .bottom-header{
   max-width: 1400px;
   margin: 0 auto;
   background-color: #fff;
   display: grid;
   grid-template-columns: repeat(20, 1fr);
}

.business-box .top-header{
   padding: 10px 0;
}

.business-box .top-header .row{
   margin: 0;
   gap: 10px;
   justify-content: space-between;
}

.business-box .top-header .row .col-1{
   flex: 0 0  7%;
   max-width: 7%;
   padding: 0;
}

.business-box .top-header .row .col,
.business-box .top-header .row .col-auto{
   align-self: center;
   padding: 0;
}

.business-box .top-header .navbar,
.business-box .bottom-header .navbar {
   grid-column: 2 / span 18;
}

.business-box .top-header .navbar{
   display: flex;
   flex-direction: row;
}

.business-box .top-header img{
   margin: 0;
   padding: 0;
   width: 100%;
   border-radius: 0%;
}

.business-box .top-header .navbar-brand{
   margin: 0;
   width: 100%;
}

/* .business-box .bottom-header .navbar-nav{ 
   justify-content: space-between;
   width: 100%;    
} */

.business-box .bottom-header .navbar-nav a{
   margin-bottom: -2px;
}

.business-box .bottom-header .navbar-nav a:link,
.business-box .bottom-header .navbar-nav a:visited{
   border-bottom: 2px solid transparent;
   transition: border-bottom 0.8s;
  
}
.business-box .bottom-header .navbar-nav a:hover,
.business-box .bottom-header .navbar-nav a:active{ 
   border-bottom: 2px solid rgb(149, 149, 149, .8); 
}

.business-box .header .nav-link{
   margin: 0 5px;
   padding: 10px;
   color: rgb(94, 65, 65)!important;
}

.business-box .top-header a.nav-link:nth-child(1):hover,
.business-box .top-header a.nav-link:nth-child(2):hover{
   padding: 10px;   
   background-color: #EAEAEA;
}

.business-box .top-header a.nav-link:nth-child(1):hover{     
   border-radius: 10px;   
}

.business-box .top-header a.nav-link:nth-child(2):hover{
   border-radius: 50%;   
}

.business-box .bottom-header button.navbar-toggler{
   margin: 0 13px 10px 0;
   border: none;
}

.business-box a{    
   color: #fff;
}
.business-box a:hover,
.business-box a:focus
{    
   color: #fbb016;
}
.business-box .dropdown-menu a{
   color:#000;
}

.business-box .top-header i.icon{
   margin-top: 5px;
}

/* BOTTOM HEADER TOGGLE */
.business-box .bottom-header .navbar-toggler{
   padding: 0;
}
/*FOOTER styiling */
.footer {
   background-color: #343a40;
   padding: 20px 0 10px;
}

.footer .row{
   max-width: 90%;
   margin: 0 auto;
}

.footer h3{
   color: #fbb016;
   padding: 0 15px ;
}
.footer p{
   color: #f8f9fa;
   font-size: .8em;
   letter-spacing: 0.7px;
   padding: 5px 10px;
}

/* DROPDOWN styling */
.business-box .dropdown-toggle::after{
   content: none;
}

/* RESPONSIVE styling */
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
   .business-box .top-header .row .col-1{
      flex: 0 0 9%;
      max-width: 9%;
   }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
.business-box .header .navbar-nav {
   flex-direction: row;
}
.business-box .top-header .row .col-1{
   flex: 0 0 11%;
   max-width: 11%;
}

.footer .nav{
   flex-direction:column-reverse;
}

.footer .nav-link{
text-align: left; 
padding: 10px 0;
}
}

/* Small devices (landscape phones, less than 576px) */
@media (max-width:576px){
   .bottom-header .navbar-collapse > div{
   flex-direction: column!important;
}

.business-box .top-header .row .col-1{
   flex: 0 0 15%;
   max-width: 15%;
}

.business-box .bottom-header .navbar{
   justify-content: flex-end;
}
}
/* need to check container width in different media */
@media only screen and (min-width: 768px) and (max-width: 2000px){
.ui.container {
   width: 100%;
}
}

 .modalSet {
  height: auto !important;
  top: auto !important;
  left: auto !important;
  bottom: auto !important;
  right: auto !important;
}

.navText{
   font-size: 2em;
}





