.client-box .mt-5{
}
.client-box .container{
    padding: 0;
    margin: 0;
}

.client-box a{
    color: lightslategray;
}

.box-one,
.box-two{
    background-color: #fff;
    margin: 10px auto;
    padding: 0px 15px;
}

.box-one .col{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
/* .box-one .ui.image, */
.client-box .ui.image{
    width: 70%;
    border-radius: 20px;
    height: auto;
}

.box-two .col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.box-one .col-6,
.box-two .col-6{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-one .col-6{
    background-color: aquamarine;
}
.box-two .col-6{
    background-color: pink;
}

.box-announcment,
.styleCategory-container .container,
.box-banner .container,
.whyUs-container{
    padding-right: 0;
    padding-left: 0;
}

.box-announcment{
    text-align: center;
    padding: 15px;
    background-color: rgb(71, 53, 30);
    color:#fff;
}

.styleCategory-container{
}

.body-banner .container{
    padding-right: 0;
    padding-left: 0;
    height: 200px;
    overflow: hidden;
}

.box-banner .ui.image{
    width: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.body-banner {
    min-height: 18rem;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
}

.box-list{
    width: 70%;
    margin: 20px auto;
}

.box-list .btn{
    margin: 0 auto;
    width: 40%;
    background-color: #fbb016;
    border-color: #fbb016;
    border-radius: 1rem;

}
.box-list a{
    color: #fff;
    text-decoration: none;
}

.box-list a:hover,
.box-list a:focus
{
    transform: translateY(-2px);
    background-color: #fbb016;
    color: #fff;
}


.overTop-container{
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.overTop-title{
    background-color: #fff;
    grid-column: 4 / span 6;
    margin-top: -66px;
    padding: 20px;
    z-index: 10; 
    border-radius: 2rem 2rem 0 0;
    text-align: center;
}

.overTop-title h3{
    letter-spacing: 1px;
}

/* Responsive */
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .overTop-title{
        grid-column: 3 / span 8;
    }
}


@media (max-width: 575.98px) {
    .box-one .row{
        flex-direction: column;    
    }
    .box-two .row{
        flex-direction: column-reverse;
        margin-bottom: 20px;
    }
    .box-one .col{
        text-align: center;
    }
    .box-one .col-6,
    .box-two .col-6{
        flex: 0 0 70%;
        max-width: 70%;   
        margin: 10px auto;
    }

    .box-one .col-6 .ui.image,
    .box-two .col-6 .ui.image{
        width: 80%;
    }

    .box-two .col{
        align-items: center;
    }
}




/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 

 }
