.customOfferings-box{
    margin: 0 auto;
    /* background-color: gray; */
    padding: 20px 10px;
}
.customOfferings-box .row{
    margin: 0 auto;
    padding-top: 10px ;

}

.customOfferings-box h5{
    margin: 0;
}
.customOfferings-box .notes{
    font-size: 11px;
    color: red;
    /* margin-bottom: 10px; */

}
/* .customOfferings-box i.mn.flag:not(.icon),
.customOfferings-box i.in.flag:not(.icon),
.customOfferings-box i.br.flag:not(.icon){
   margin: 0 8px;   
} */

.customOfferings-box .img-box{
    /* background-color: green; */
    margin: 0;
}


.customOfferings-box .row img{
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0;
}

.customOfferings-box ul li{
    display: inline-block;
    padding: 5px 0;
    margin: 10px 5px 0 0;
    font-size: 14px;
}
.customOfferings-box ul li a{
    padding-right:8px;
}

.customOfferings-box li a:hover:after {
    display: block;
    position: absolute;
    top:50%;
    left:100%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: rgb(94, 65, 65);
    padding: 5px 5px 3px;
    /* border-radius: 10px; */
}
.customOfferings-box li:nth-child(1) a:hover:after {
    content: url(../../../../assets/KinkyCurly.jpg); /* no need for qoutes */

}
.customOfferings-box li:nth-child(2) a:hover:after {
    content: url(../../../../assets/bodyWave.jpg); /* no need for qoutes */
      
}
.customOfferings-box li:nth-child(3) a:hover:after {
    content: url(../../../../assets/KinkyStraight.jpg); /* no need for qoutes */
 
}
.customOfferings-box li:nth-child(4) a:hover:after {
    content: url(../../../../assets/straight.jpg); /* no need for qoutes */
    
}


@media (max-width: 575.98px) {
    .customOfferings-box ul li{
    display: block;
}
    .customOfferings-box li a:hover:after {
    left:50%;
}
}



/* @keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}
.pulse {
    animation-name: pulse_animation;
    animation-duration: 5000ms;
    transform-origin:70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: hotpink;
} */