.store-main {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    /* background-color: red; */
}

.store-main h1{
    color:#000;
    font-size: 20px; 
}

.store-main .spanText{
    color: gray;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.store-main .btn-sm{
    /* background-color: aqua; */
    margin-left: -8px;
}
.banner{
    /* background-color: blue; */
    grid-column: 1 / span 16;
}

.banner img{
    padding: 0;
}

.businessInformation{
    /* background-color: aqua; */
    grid-column: 3 / span 12;
    row-gap: 10px;
}
.vl {
    border-left: 6px solid black;
    height: 500px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }

.businessInfoTop{
    /* background-color: burlywood; */
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 10px 0;
}

.businessInformationBottom{
    grid-column: 3 / span 12;
}

.businessInformationBottom .row{
    /* background-color: brown; */
}

.businessInformationBottom .col-sm-3{
    /* background-color: blue; */
    margin: 5px 0;
    /* flex: 0 0 33%;
    max-width: 33%; */
}

.businessImageAndLocation {
    /* background-color: brown; */
    grid-column: 1 / span 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.store-main .rounded-circle{
    /* background-color: aqua; */
    width: 100%;
    object-fit: contain;
}


.businessName{
    /* background-color: rgb(35, 78, 80); */
    grid-column: 4 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px 0;
}

.businessOwnerImageAndName{
    grid-column: 10 / span 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: cyan; */
}

.businessOwnerImageAndName .image-cropper{
    position: relative;
    width: 75%;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 20px;
}

.businessOwnerImageAndName .image-cropper::after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.businessOwnerImageAndName .image-cropper img{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
}
p{
    white-space: pre-line;
}

.businessOwnerImageAndNameMobile {
    display: none;
}

.store-mainbusinessOwnerImageAndName .ui.small.image{
    width: 100px;
    height: 100px;
    max-width: 80%;
}
.center{
    grid-column-start: 12;
    grid-column-end: 15;
}

@media (max-width: 991.98px) { 
      .businessInformationBottom .col-sm-3{
    flex: 0 0 33%;
    max-width: 33%;
}

}
 @media (max-width: 767.98px) {
     .businessInfoTop{
       grid-template-columns: repeat(9, 1fr);
       /* background-color: aqua; */
     }

    .businessInformationBottom .col-sm-8,
    .businessInformationBottom .col-sm-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .businessOwnerImageAndName{
        display: none;
    }

    .businessOwnerImageAndNameMobile{
        grid-column: 1 / span 12;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        column-gap: 10px;
        margin: 15px;
        padding: 15px 0;
        width: 100%;
        align-items: center;
        border-bottom: 0.8px solid rgb(128, 128, 128, 0.2);
        border-top: 0.8px solid rgb(128, 128, 128, 0.2);
    }
    
    .businessOwnerImageAndNameMobile .image-cropper{
        grid-column: 1 / span 3;
        position: relative;
        width: 90%;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto;
    }

    .businessOwnerImageAndNameMobile .image-cropper::after{
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .businessOwnerImageAndNameMobile .image-cropper img{
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .store-main .spanText{
        font-size: 20px;
    }
}

 @media (max-width: 575.98px) {
    .businessInformation,
    .businessInformationBottom{
        grid-column: 2 / span 14;
    }

    .businessInfoTop{
       grid-template-columns: repeat(7, 1fr);
    }

  .businessInformationBottom .col-sm-3{
    flex: 0 0 50%;
    max-width: 50%;
    }
 }
