/* GENERAL STYLING */
.item-box{
    padding: 20px 10px;
    max-width: 1400px;
    margin: 0 auto;
}
.item-box .btn{
   border-radius: 20px;
}
.item-box .card{
   box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
}
.item-box .card-header{
    background-color:#EAEAEA;
}    
.item-box label{
    margin-top: .5rem;
}

.item-box .col-sm-5,
.item-box .col-sm-7 {
    padding: 10px 25px;
}
 .item-box .box{
     margin-top: 35px;
 }

/* TEXT STYLING */
.col-sm-5 .title-sm-5 .row{
    column-gap: 15px;
    align-self: center;
    margin: 0;
    padding-bottom: 10px;
}

.item-box h1{
 margin: 15px 0 10px; 
 font-weight: 200;
 letter-spacing: 1px;
}

.item-box h3{
 margin: 2px 0; 
}

.item-box .price-title{
    font-size: 2em;
    font-weight: bold;
}

.item-box .price-off{
    font-size: 1.2em;
    font-weight: bold;
    padding-top: 7px!important;
}

.item-box span{
    color: rgb(94, 65, 65);
}

.item-box .small-text{
    font-size: 1em;
    
}

.item-box .big-text{
    font-size: 1.7em;
    line-height: 35px;
}

.item-box .underline-text{
    text-decoration: underline dashed;
    line-height: 1px;
    text-underline-position: under;
}

/* CAROUSEL & GALARY STYLE */
.gallery-box .carousel-control-next-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.gallery-box .carousel-control-prev-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.gallery-box{
    display: flex;
    flex-direction: row;
}

.gallery-box .box1{
    width: 70px;
    margin: 0 auto;
}

.gallery-box .img-box{
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center ;
}
.gallery-box .img-box::after{
    content: "";
    display: block;
    padding-bottom: 100%;

}

.gallery-box .img-box img{
    position: absolute;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 0;
}

.gallery-box .box1 img{
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0px;
    border-radius: 5px;
}

.gallery-box .box1 img:hover{
    border: 3px solid black;
    border-radius: 5px;
}

.gallery-box .box2{
    width: 90%;
} 

.gallery-box .box2 img{
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
}


/* LEFT SIDE STYLING COL-SM-7*/
.title-sm-7{
    display: none ;
    margin-top: 25px;
}

.title-sm-7 .row{
    column-gap: 15px;
    padding: 10px 0;
    margin: 0;
}

.title-sm-7 .row p{
    margin: 0;
    padding:0;
}
/* RIGHT SIDE STYLING COL-SM-5*/
.description-box{
    margin: 10px 0;
}
.description-box p{
    font-size: 1.3em;
}

.vl {
    border-left: 2px solid black;
    height: 500px;
    padding: 1;
  }

.item-box .col-sm-5 hr {
  flex:1;
  padding: 0;
}


.item-box .col-sm-5 .css-2b097c-container{
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 15%);
    margin-bottom: 10px;
}

.item-box .col-sm-5 .btn-container{
    display: flex;
    flex-direction: column;
}


.item-box .col-sm-5 .btn{
     margin: 10px 0 5px;
}

.item-box .col-sm-5 .btn-outline-secondary,
.item-box .col-sm-5 .btn-secondary{
    padding: 10px;
}
/* customize box */
.item-box .col-sm-5 .customize-box{
    margin: 15px 0;
}
/* Estimate Delivery Style  */
.delivery-container .row{
    /* margin: 10px 0 0; */
}
.delivery-container .box .col{
    /* margin: 0 18px 5px; */
}
.item-box .delivery-wrap{
    margin-top: 10px;
    padding: 0 20px; 
}
.item-box .delivery-wrap img{
    width: 40px;
    padding: 2px;
}
.item-box .delivery-wrap p{
    padding: 0 10px;
    font-weight: bold;
}
.delivery-info{
    display: flex;
    justify-content: space-between;
    margin: 0 5px 10px;
    
}

.shop-policies,
.shop-owner{
    margin: 15px 0;
}

.shop-owner .box1{
    width: 80px;
}
.shop-owner .box1 img{
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0;
}
.shop-owner .box2{
    margin: 0 10px;
}
.shop-owner a {
    color: rgb(26, 25, 24)!important;
    text-decoration: underline;
}
.item-box .table-sm td{
    padding:  8px 15px;
}

.item-box .table-sm td:nth-of-type(1){
    background-color:#EAEAEA;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    max-width: 100px;
    
}
/* TOOLtip */


/* MEDIA QUERY */
 /* Large devices (desktops, less than 1200px) */
 @media (max-width: 1199.98px) { 
}

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }
@media (max-width: 900px) { 
    .item-box .col-sm-5,
    .item-box .col-sm-7{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .title-sm-7{
    display: block ;
    }
    .title-sm-5{
    display: none ;
    }

 }

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .title-sm-7 .col-sm-2{
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (max-width: 575.98px) {
.gallery-box{
   flex-direction: column-reverse;
}

.gallery-box .box1{
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   width:100%;
   margin: 0 ;
}

.gallery-box .img-box{
    width: 15%;
}

.gallery-box .box2{
    width: 100%;
}

.title-sm-7 .col-sm-2{
    flex: 0 0 35%;
    max-width: 35%;
}
}