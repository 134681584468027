.footerCheckout {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.formatCheckout {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.formatServices {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
  margin-top: 20px;
}

.formatPayment {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
  margin-top: 20px;
  margin-bottom: 90px;
  text-align: center;
}

.formatButton {
  display: block;
  width: 100%;
  border: none;
  background-color: #04aa6d;
  padding: 14px 28px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  color: white;
  border-radius: 8px;
  margin-bottom: 10px;
}

.loadingToStripe {
  border-radius: 20px;
  min-width: 999px;
  min-height: 200px;
}

.load {
  margin: 0 auto;
}

.checkoutContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  /* min-height: 100vh; */
}

.checkoutBody {
  grid-column-start: 2;
  grid-column-end: 16;
  /* min-height: 100vh; */
}

.checkoutStepper {
  grid-column-start: 1;
  grid-column-end: 17;
}

.checkoutHeader {
  grid-column-start: 1;
  grid-column-end: 17;
}

.checkoutMainSection {
  grid-column-start: 4;
  grid-column-end: 10;
}

.checkoutItemSection {
  grid-column-start: 11;
  grid-column-end: 15;
}

.priceSubtoal {
  flex: 1;
  text-align: right;
}

.priceRow {
  display: flex;
  line-height: 1em;
  align-items: center;
  padding: 9px 0;
}

.validatonError {
  color: red;
}

@media (max-width: 576px) {
  .checkoutBody {
    grid-column: 1 / span 17;
  }
}
