.aboutContainer{
    display: grid;
    grid-template-columns:repeat(18, 1fr);
}

.title{
    grid-column: 2 / span 18;  
    padding: 20px 0;
    /* color:brown */
}
.title a{
color:rgba(0,0,0,.87)
}

.mainContainer{
    grid-column: 1 / span 18; 
    display: grid;
    grid-template-columns: repeat(18, 1fr); 
    background-color: rgb(243, 200, 83);
    padding: 20px 0;
}

.mainMessage,
.aboutUSBuyerText{
    grid-column: 3 / span 8;  
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainMessage h1 {
    font-size: 4em;
    font-weight: 1000;
}

.mainMessage p {
    font-size: 20px;
    font-weight: 1000;
}

.mainImg,
.aboutUSBuyerImg{
    grid-column: auto / span 6;  
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.mainImg img{ 
width: 100%;
height: auto;
}

.bussinessDetails{
    grid-column: 2 / span 16; 
}

.bussinessDetailsTitle{
    grid-column: 2 / span 16;
    padding: 40px 0 30px;
    text-align: center;
}

.howItWorksContainer{
    grid-column: 2 / span 16;
    padding: 10px 0 40px;
    display: grid;
    grid-template-columns:repeat(12, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
}
.howItWorksContainer h3{
    font-size: 1.5rem;
    text-align: center;
    margin-top: 0; 
}

/* leftSection */
.leftSection{
    grid-column: 1 / span 4;
}

/* centerSection */
.centerSection{
    grid-column: auto / span 4;
}

/* rightSection */
.rightSection{
    grid-column: auto / span 4;
}

.paymentInformation {
    grid-column: 1 / span 18;
    display: grid;
    grid-template-columns:repeat(14, 1fr);
    background-color: rgb(243, 200, 83);
}

.infoBody{
    margin-top: 40px;
    grid-column: 4 / span 8;
}

.infoBodyTitle {
    text-align: center;
}

.infoBenefits{
    display: flex;
    flex-direction: row;
}

.infoBenefits > div {
    flex: 1 1 0px;
}
.infoBenefits > div p{
    display: inline-block;
    padding-left: 5px;
}

.infoFees{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 10px;
    margin: 20px 0;
}

.iconFees{
    grid-column: 1 / span 1;
    background-color: #fff;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.iconFees i.icon{
  height: auto;
  margin: 0;
}

.textFees{
    grid-column: 2 / span 7;
}

.securePaymentProvider {
    margin-top: 15px;
    margin-bottom: 42px;
    font-size: 10px;
    grid-column: 6 / span 6;
}

.infoBottomSection{
    grid-column: 1 / span 18;
    display: grid;
    grid-template-columns: repeat(18, 1fr); 
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.infoBottomSection h4,
.infoBottomSection h2{
margin-bottom: 0;
}

.leftBottomSection{
    grid-column: 3 / span 7;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.leftBottomSectionImg{
    grid-column: auto / span 7;
}

.rightBottomSection{
    grid-column: auto / span 7;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.rightBottomSectionImg{
    grid-column: 3 / span 7;
}


/* Buyer page styling*/

.topImg{
    grid-column: 4 / span 12; 
}
.topMessage{
    grid-column: 2 / span 16;
    text-align: center; 
    padding: 20px 0;
}

.aboutUSBuyerContainer{
    grid-column: 1 / span 18;
    background-color: #F8EEE9;
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.missionTitle{
    grid-column: 2 / span 16;
    text-align: center;
    padding: 70px 0;
}
.missionTitle h2{
    margin: 10px 0;
}

.journeyContainer{
    grid-column: 1 / span 18;
    background-color: #F8EEE9;
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.journeyText{
    grid-column: 2 / span 16;
}

.aboutUSBuyerText p,
.journeyText p{
    font-size: 1.2rem;
    line-height: 2rem;
}
.bottomBuyerImg{
    grid-column: 16 / span 2;
    padding-top: 20px;
}
/* MEDIA QUERY  */
@media (max-width: 824.98px) {
    .mainMessage{
        grid-column: 2 / span 16;  
    }
    .mainImg{
        grid-column: 6 / span 8; 
    }
}

@media (max-width: 767.98px) {
    .leftSection{
        grid-column: 1 / span 6;
    }
    .centerSection{
        grid-column: auto / span 6;
    }
    
    .rightSection{
        grid-column: 4  / span 6;
    }
    .paymentInformation {
        grid-template-columns:repeat(8, 1fr);
    }
    .infoBody{
        grid-column: 2 / span 6;
    }
    .securePaymentProvider {
        grid-column: 4 / span 4;
    }

    .leftBottomSection{
        grid-column: 2 / span 16;
    }
    
    .leftBottomSectionImg{
        grid-column: 4 / span 12; 
        order: -1;
    }
    
    .rightBottomSection{
        grid-column: 2 / span 16;
    }
    
    .rightBottomSectionImg{
        grid-column: 4 / span 12;
    }
    
    .aboutUSBuyerText{
        grid-column: 2 / span 16;  
    }
    .aboutUSBuyerImg{
        grid-column: 4 / span 12; 
    }
    
}

@media (max-width: 575.98px) {
    .mainMessage h1 {
        font-size: 3em;
    }
    .mainImg{
        grid-column: 4 / span 12; 
    }
   .leftSection,
   .centerSection,
   .rightSection{
        grid-column: 1 / span 12;
    }
    .infoBenefits{
        flex-direction: column;
        row-gap: 1rem;
    }
    .securePaymentProvider {
        grid-column: 2 / span 6;
    }
    .topImg{
        grid-column: 2 / span 16; 
    }
    .bottomBuyerImg{
        grid-column: 15 / span 3;
    }
}