.section-container {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}

.section-container .col{
  padding: 0;
  grid-column: 2 / span 18;
}

.top-section h2,
.top-section h3{
  margin: 0;
}

.top-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
 

 .seeMore-box{
   display: flex;
   flex-direction: row;
   column-gap: 10px ;
   align-items: center;
 }

 .seeMore-box h2{
}

 .section span  { 
     background:#fff; 
     padding:0 10px; 
     font-size: 28px;
 }
