@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
.App {
  /* text-align: center; */
  background-color: white;
  margin: 0;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.floatBtn{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;

}
/* .modal {
  height: auto !important;
  top: auto !important;
  left: auto !important;
  bottom: auto !important;
  right: auto !important;
} */

html {
  font-size: 62.5%;
  /* font-family: 'Dancing Script', cursive; */
  /* font-family: 'ZCOOL KuaiLe', cursive; */
}

body{
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* font-family: 'Roboto',sans-serif;
  font-family: 'Dancing Script', cursive; */
  /* font-family: 'ZCOOL KuaiLe', cursive; */
}
/* h1, h2, h3, h4, h5, h6{
 margin: 0;
 padding: 0;
} */

.container {
  flex: 1 1 auto;  /*grow vertically*/
  
}


