.wigseller-box{
    max-width: 1280px;
    /* background-color: brown; */
}
/* HEADINGS STYLE */
.wigseller-box h3{
    margin: 0;
    padding: 10px 10px 5px;
    color: #000000;
    font-size: 18px;
}
.wigseller-box h4{
    color: #000000;
    margin: 10px;
}

.wigseller-box .row{
    margin: 0;
}

.wigseller-box .col-sm-3{
    padding: 5px;
}

.creatorCard-content .col-sm-3{
    /* background-color: aquamarine; */
}
.card-container {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 25%);
    padding: 0 0 10px 0;
}
.roundCard-container{
    /* background-color: cornflowerblue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.wigCard-container {
    display: flex;
    flex-direction:row;
}

.wigcard-img{
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.creater-img{
    position: relative;
    width: 100%;
    overflow: hidden;
}

.creater-img::after{
    content: "";
    display: block;
    padding-bottom: 100%;
}

.creater-img img{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
}

img{
    border-radius: 0.25rem .25rem 0 0;
}
.wigseller-box .col-sm-4{
    margin-bottom: 20px;
}

.wigseller-box .card{
    width: 20rem;
    border-radius: .4rem;
    margin: 0 auto;
}

.wigseller-box .review-box,
.wigseller-box .price-box{
    display: flex;
    flex-direction: row;
}
.wigseller-box .price-off{
    display: flex;
    flex-direction:column;
    column-gap: 5px;
    margin-left: 10px;
    /* padding: 0 15px; */
    color: green;
}

.wigseller-box .price-off h6{
    font-size: 11px;
    line-height: 15px;
}

.wigseller-box .price-off:hover{
    color: green;
}

.wigseller-box .rating-box{
    display:flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    padding: 0 10px;
    font-size: 10px;
    /* margin-left: 15px; */
}

.wigseller-box .price-title{
    font-size: 16px;
    font-weight: bold;
    /* margin-left: 15px; */
    padding:5px 10px;
    color: #000;
}

.roundCard-container h4{
    text-align: center;
}

.wigseller-box .loading-page{
    display: flex;
    justify-content: center;
}

/* RESPONSIVE */
@media (max-width: 767.98px) {
    .wigseller-box .col-sm-3{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (max-width: 575.98px) {
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }