.styles-content{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    color: rgb(94, 65, 65);
    padding: 0 35px;
}

.styles-content h2{
    padding: 0 20px;
    margin: 0;
}

.content-boxes{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.frontal-closure{
    grid-column: 1 / span 6;
    padding: 20px;

}

.texture-color{
    grid-column: 7 / span 6;
    padding: 20px;
}

.lace-container{
    /* background-color: burlywood; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 25%);
    width: 100%;
    position: relative;
}

.lace-container::after{
    content: "";
    display: block;
    padding-bottom: 100%;
}
.lace-container span{
    color: rgb(94, 65, 65);
    font-size: 32px;
    font-weight: bold;
    padding: .5rem 1rem;
    background-color: rgb(255, 255, 255, 0.7);
}

.square-lace{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
    padding: 20px;
}
.frontal-link,
.closure-link{
    border-radius: 15px;
    border: 1px gray solid;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frontal-link{
    background-repeat: no-repeat;
    background-size: cover;
}

.closure-link{
    background-repeat: no-repeat;
    background-size: cover;
}

.type-link{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    position: absolute;
    width: 100%;
    height: 100%;
    
}

.kink-link,
.other-link{
    flex: 0 0 50%;
    max-width: 50%;
}

.kink-link{
    padding: 20px 20px 0;
}
.other-link{
    padding: 0 20px 20px;
}
.square-box-img{
    position: relative;
    width: 100%;
    overflow: hidden;
}

.square-box-img::after{
    content: "";
    display: block;
    padding-bottom: 100%;

}

.kink-link img,
.other-link img{
    position: absolute;
    border-radius: 15px;
     width: 100%;
    height: 100%;
    padding: 0;
}

.type-link p{
    margin-bottom: 10px;
    font-size: 14px;
    color: rgb(94, 65, 65);
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .styles-content{
        padding: 0 20px;
    }
    
}

@media (max-width: 575.98px) {
    .styles-content h2{
        padding: 0;
    }
    
    .content-boxes{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    
    .frontal-closure,
    .texture-color{
        grid-column: 1 / span 4;
        padding: 20px 0;
    
    }
/*     
    .texture-color{
        grid-column: 1 / span 4;
    } */
}