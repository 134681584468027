.pricing-box{
    margin: 0 auto;
    padding-top: 30px ;
    width: 100%;    
}

.pricing-box h1{
    text-align: center;
    letter-spacing: 1.5px;
}

.table-box{
    padding: 10px 0 30px 0;
}

.ui.basic.table{
    width: 80%;
    background-color:  #f8f9fa;
    margin: 0 auto;
    padding: 0 15px;
}

.pricing-box .btn-block{
    width: 50%;
    margin: 15px auto;
}

.faq-box{
    background-color: #f8f9fa;
    padding: 25px 40px;
    margin: 0;
}
.faq-box h3{
   text-align: center;
}

.faq-box .col{
    padding: 20px 10px;
}
/* @media (max-width: 992px){
.container {
    max-width: 100%;
}
}

@media (min-width: 992px){
.container {
    max-width: 100%;
}
}

@media (max-width: 768px){
.container {
    max-width: 100%;
}
}

@media (min-width: 768px){
.container {
    max-width: 100%;
}
} */


@media only screen and (min-width: 768px) and (max-width: 2000px){
.ui.container {
    width: 100%;
}
}