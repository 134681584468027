.customWigDetails-box{
    /* background-color: lawngreen; */

}

.customWigDetails-box h1{
    letter-spacing: 2px;
    font-size: 36px;
    line-height: 38px;
    font-weight: 300;    
}
.customWigDetails-box h4{
    font-size: 20px;
    line-height: 1.6;
    /* font-weight: 600;    */
}
.customWigDetails-box p{
    font-size: 14px;
    line-height: 1.7; 
    margin: 0;
}
.customWigDetails-box .text-delivery{
    margin-top: 5px;
    border-bottom: dashed 1px ;
}
.customWigDetails-box span{
    font-size: 26px;
    line-height: 1.7; 
}
.customWigDetails-box .row{
    margin: 20px 0;
    
}
.customWigDetails-box .row img{
    width: 40px;
    height: 40px;
}
.customWigDetails-box .delivery-wrap img{
    width: 50px;
    height: 50px;
}

.customWigDetails-box .col i.icon{
    /* background-color: lightseagreen; */
    /* margin-bottom: 20px; */
}
.customWigDetails-box span{
 color: #5e4141;
}
.customWigDetails-box span:hover{
 color: #5e4141;
}

.customWigDetails-box .wrap {
  display: flex;
  align-items: center ;
  width: 50%;
  margin: 0 auto;
}
.customWigDetails-box .delivery-wrap{
  /* width: 65%; */
  /* margin: 0 auto; */
  padding: 20px;
}
.customWigDetails-box hr {
  flex:1;
  padding: 0 15px;
}

.customWigDetails-box .row:last-child {
    display: flex;
    justify-content: center;
}

.customWigDetails-box .row:last-child .ui.button{
   background-color: #fbb016;
   letter-spacing: 2px;
   color: #fff;
   overflow: hidden;
}
.customWigDetails-box .row:last-child span{
  color: #fff;
}

.customWigDetails-box .row:last-child .ui.button:hover{
   background-color: #5e4141;
   
}

/* .customWigDetails-box .row:last-child .ui.button:hover span{
    display: none;
} */
/* .customWigDetails-box .row:last-child .ui.button:hover:before{
   content:"Made with love";
} */



@media (max-width: 575.98px) {
.customWigDetails-box .row .col{
    /* background-color: indigo; */
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0;
}
.customWigDetails-box .row .col-10{
    background-color: indigo;
}
}
 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    
 }

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

 /* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
   
 }