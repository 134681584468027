.invoice-box{
    margin: 20px 0;
}

.invoice-box .ui.basic.table{
    width: 100%;
    background-color: #fff;
    position: relative;
    padding: 0;
}

.invoice-box .ui.button{
    margin-top: 10px;
    padding: 5px;
    text-align: center;
}

.fade{
    opacity: 1;
}

/* .td.tr{
    background-color: pink;
}

.ui.table .td:last-child .tr{
    background-color: blue;
} */

.invoice-box .text{
    /* background-color: blueviolet; */
    text-decoration: underline;
    padding: 2px 0;
    line-height: 28px;
    letter-spacing: .5px;
}

.invoice-box .form-control{
    margin-bottom: 5px;
}