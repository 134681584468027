.rules-box{
    margin-top: 10px;
}

.rules-box .row{
    margin: 10px 0;
}
.rules-box .rules-header{
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
}
.rules-box .col{
    margin-top: 10px;
    border-bottom: 1px rgba(128, 128, 128, 0.2) solid;
    padding: 0 0 10px;
}
.rules-box .row:last-of-type .col{
    border-bottom: none;
}
.rules-box a{
    color:brown
}
 /* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

  /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
    .rules-box .col-sm-4{
        flex: 0 0 40%!important;
        max-width: 40%!important;
    }
    .rules-box .col-sm-8{
        flex: 0 0 60%!important;
        max-width: 60%!important;
    }
}

  /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    .rules-box .ui.vertical.menu{
        max-width: 7rem;
    }
    .rules-box .ui.grid{
        display: flex ;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;

    }
    .rules-box .ui.grid>[class*="twelve wide"].column{
        max-width: 70%!important;
    }
}


