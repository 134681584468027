.sidebar-box{
    position: relative;
    padding-top: 20px;
}

.sidebar-box .navbar-expand{
    flex-flow: column nowrap;
    padding: 0;
}

.sidebar-box .navbar-expand:nth-child(1){
        display: none;
        /* position: absolute; */
        /* top: 10px;
        left: 10px; */
}
/* .sidebar-box .navbar-expand:nth-child(2){
    width: 100%;
    padding: 10px 15px;
    background-color: chartreuse;
} */

.sidebar-box .navbar-expand:nth-child(2) .navbar-nav{
        /* display: none; */
        /* position: absolute;
        top: 15px;
        left: 10px; */
}

.submenu-box{
    /* background-color: chartreuse;  */
    width: 100%;   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
}

.submenu-box a{
    /* background-color: yellow; */
}
.submenu-box .nav-link{
    /* background-color: red; */
    display: flex;
    width: 100%; 
    padding: 5px 0;      
}

.submenu-box .nav-link>div{
    /* background-color: hotpink; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-self: center;
    padding: 5px 0;
}

.submenu-box .nav-link div:nth-child(1){
    /* background-color: red; */
    width: 60%;
    align-items: flex-end;
    padding-right:15px;    
}
.submenu-box .nav-link div:nth-child(2){
    /* background-color: teal;  */
    align-items: flex-start;
    width: 180px;
}
.submenu-box .nav-link div:nth-child(3){
    /* background-color: black; */
     align-items: flex-start;
}

.subnav-box .nav-link{
    /* background-color: blueviolet; */
    display: flex;
    width: 80%;
    margin: 0 auto;
    align-items: center;
}

/* .subnav-box .nav-link>div{
    
} */
.subnav-box .nav-link div:nth-child(1){
    /* background-color: chartreuse; */
    justify-content: flex-end;
    width: 40%;
}


@media (max-width: 575.98px) {
    .submenu-box{
    background-color:hotpink;  
    /* width: 200px;  */
}

.sidebar-box .navbar-expand{
    /* background-color: rgba(255, 0, 0, 1);  */
    position: absolute;
    top:0px;
    left: 0;
    z-index: 10;
    padding: 10px 0 20px 5px;
}
   .sidebar-box .navbar-expand:nth-child(1){
        display: block;
        /* background-color: pink; */
    }
    .sidebar-box .navbar-expand:nth-child(2){
    /* width: 200px; */
    /* height: 93vh; */
    /* padding: 0 15px; */
    /* background-color: chartreuse;
    position: absolute;
    top:0px;
    z-index: 10; */
    /* transform: translate(-100%, 0); */
}
    /* .sidebar-box .navbar-expand:nth-child(2).open{
    transform: translate(100%, 0);
} */
    .sidebar-box .navbar-expand:nth-child(2) .navbar-nav{
        display: block;
        background-color: chartreuse;
    }
}

 /* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    
 }

 /* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {  }

 /* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
   
 }