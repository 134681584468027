.premadeWigs-box{
    /* background-color: gold; */
    margin: 0 10px;
  }
  
  .premadeWigs-box .card{
    border: none;
  }
  
  .premadeWigs-box .card:hover,
  .premadeWigs-box .card:focus
  {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 1px 6px 0 rgb(34 34 34 / 25%);
  
  }
  .premadeWigs-box .card img{
      width: 100%;
  }
  
  .premadeWigs-box .card-body{
    /* background-color: green; */
    padding: 0 10px 10px ;
  }
  
  .premadeWigs-box .card-title.h5{
      color:black;
      margin: 5px 0 0;
  }
  
  .premadeWigs-box .text-muted {
      color: #5a6269!important;
  }
  .premadeWigs-box .col-sm-4 {
      margin: 10px 0;
      padding: 10px;
  }
  
   /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) { 
      .premadeWigs-box .col-sm-4{
          flex: 0 0 33%!important;
          max-width: 33%!important;
     } 
   }
  
   
   /* Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
   }
  
  @media (max-width: 575.98px) {
    .premadeWigs-box .col-sm-4{
          flex: 0 0 50%!important;
          max-width: 50%!important;
     }
  }